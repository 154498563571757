import React from 'react';
import './MainPageFilters.scss';
import { Col, Row, Select, Space } from 'antd';
import TagSelect from 'components/ui/TagSelect';
import { searchTag } from 'components/ui/TagSelect/TagSelect';
import { useAppSelector } from 'store';
import Search from 'antd/es/input/Search';
import { MainPageFiltersProps, PositionFilter } from './types';

function MainPageFilters({
    onFilterChange = async () => {},
}: MainPageFiltersProps): React.ReactElement {
    const form = React.useRef<PositionFilter>({
        search: '',
        tags: [],
        completed: undefined,
    });

    const [loading, setLoading] = React.useState(false);

    const timeout = React.useRef<NodeJS.Timeout>();

    const { tags } = useAppSelector(state => state.positions);

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Row gutter={[16, 16]} align="middle">
                <Col span={12}>
                    <Search
                        size="large"
                        onChange={e => {
                            form.current.search = e.target.value;
                            clearTimeout(timeout.current);
                            timeout.current = setTimeout(() => {
                                setLoading(true);
                                onFilterChange(form.current).then(() =>
                                    setLoading(false)
                                );
                            }, 500);
                        }}
                        onBlur={e => {
                            form.current.search = e.target.value;
                            onFilterChange(form.current).then();
                        }}
                        loading={loading}
                        className="block"
                        placeholder="поиск"
                        allowClear
                    />
                </Col>
                <Col span={8}>
                    <TagSelect
                        size="large"
                        className="block"
                        onChange={values => {
                            form.current.tags = values.map((tag: string) =>
                                searchTag(tag, tags)
                            );
                            onFilterChange(form.current);
                        }}
                    />
                </Col>
                <Col span={4}>
                    <Select
                        placeholder="Статус"
                        className="block"
                        allowClear
                        size="large"
                        options={[
                            {
                                label: 'Куплено',
                                value: true,
                            },
                            {
                                label: 'Не куплено',
                                value: false,
                            },
                        ]}
                        onChange={checked => {
                            form.current.completed = checked;
                            onFilterChange(form.current);
                        }}
                    />
                </Col>
            </Row>
        </Space>
    );
}

export default MainPageFilters;
