import React from 'react';
import './VariantCard.scss';
import { Button, Card, Tooltip } from 'antd';
import DummyImage from 'assets/dummy.jpg';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import { currencyLocaleOptions } from 'js/utils';
import { VariantCardProps } from './types';

function VariantCardActions({
    variant,
}: VariantCardProps): React.ReactElement[] {
    const actions: React.ReactElement[] = [];
    if (variant.price)
        actions.push(
            <div key="price">
                {Number(variant.price).toLocaleString(
                    'ru',
                    currencyLocaleOptions
                )}
            </div>
        );
    if (variant.count)
        actions.push(<div key="count">x{Number(variant.count)}</div>);
    actions.push(
        <div key="date">
            {dayjs(variant.dateCreated).format('DD MMMM YYYY')}
        </div>
    );
    return actions;
}

function VariantCard({
    variant,
    onDeleteVariant = () => {},
}: VariantCardProps): React.ReactElement {
    return (
        <Card
            className="variant-card"
            cover={<img src={variant.imageUrl || DummyImage} alt="" />}
            actions={VariantCardActions({ variant })}
        >
            <Tooltip title="Удалить">
                <Button
                    type="text"
                    className="delete-button"
                    onClick={() => onDeleteVariant(variant)}
                    icon={<DeleteOutlined className="red" />}
                />
            </Tooltip>
            <Card.Meta
                style={{ whiteSpace: 'pre-line' }}
                title={
                    <a target="_blank" href={variant.url} rel="noreferrer">
                        {variant.name}
                    </a>
                }
                description={variant.description}
            />
        </Card>
    );
}

export default React.memo(VariantCard);
