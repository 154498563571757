import { IPayloadAction } from 'store/type';
import { ICategoriesState, ICategory } from './types';

export function setCategories(
    state: ICategoriesState,
    action: IPayloadAction<ICategory[]>
): ICategoriesState {
    return {
        ...state,
        categories: action.payload,
        loaded: true,
    };
}

export function setLoaded(
    state: ICategoriesState,
    _: IPayloadAction<unknown>
): ICategoriesState {
    return {
        ...state,
        loaded: true,
    };
}

export function patchCategory(
    state: ICategoriesState,
    action: IPayloadAction<ICategory>
): ICategoriesState {
    return {
        ...state,
        categories: state.categories.map(category =>
            category.id === action.payload.id ? action.payload : category
        ),
    };
}

export function addCategory(
    state: ICategoriesState,
    action: IPayloadAction<ICategory>
): ICategoriesState {
    return {
        ...state,
        categories: [...state.categories, action.payload],
    };
}

export function removeCategory(
    state: ICategoriesState,
    action: IPayloadAction<ICategory>
): ICategoriesState {
    return {
        ...state,
        categories: state.categories.filter(
            category => category.id !== action.payload.id
        ),
    };
}
