import axios, {
    AxiosInstance,
    RawAxiosRequestHeaders,
    AxiosRequestConfig,
} from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

export type TokenType = 'token' | 'refreshToken' | undefined;

export function setToken(type: TokenType, token: string) {
    if (type) localStorage[type] = token;
}

export function removeToken(type: TokenType) {
    if (type) localStorage.removeItem(type);
}

export function getToken(type: TokenType): string | null {
    return type ? localStorage.getItem(type) : null;
}

export default function getApiClient(
    type: TokenType = undefined,
    params: AxiosRequestConfig = {}
): AxiosInstance {
    const headers: RawAxiosRequestHeaders = {};

    const token = getToken(type);

    if (token) headers.Authorization = `Bearer ${token}`;

    const instance = axios.create({
        ...params,
        baseURL: REACT_APP_BACKEND_URL || '/api/v1/',
        responseType: params.responseType || 'json',
        headers: { ...params.headers, ...headers },
    });

    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                window.dispatchEvent(new CustomEvent('unauthorized'));
            }
            return Promise.reject(error);
        }
    );

    return instance;
}
