import React from 'react';
import './EmojiPicker.scss';
import Picker from '@emoji-mart/react';
import data, { Skin } from '@emoji-mart/data';
import { Popover } from 'antd';
import { EmojiPickerProps } from './types';

function EmojiPicker({
    onEmojiSelect,
    style,
    defaultValue,
    name,
}: EmojiPickerProps): React.ReactElement {
    const [emoji, setEmoji] = React.useState(defaultValue || '📄');

    return (
        <Popover
            trigger="click"
            content={
                <Picker
                    data={data}
                    onEmojiSelect={(newEmoji: Skin) => {
                        onEmojiSelect?.({
                            target: {
                                name: name || 'default',
                                value: newEmoji.native,
                            },
                        });
                        setEmoji(newEmoji.native);
                    }}
                />
            }
        >
            <div
                style={{
                    ...style,
                    cursor: 'pointer',
                }}
            >
                {emoji}
            </div>
        </Popover>
    );
}

EmojiPicker.defaultProps = {
    onEmojiSelect: undefined,
    style: {},
    defaultValue: '📄',
};

export default EmojiPicker;
