import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from 'routes/routes';
import 'styles/styles.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';

function App() {
    const router = createBrowserRouter(routes);

    return (
        <div className="app">
            <ConfigProvider locale={ru}>
                <DndProvider backend={HTML5Backend}>
                    <RouterProvider router={router} />
                </DndProvider>
            </ConfigProvider>
        </div>
    );
}

export default App;
