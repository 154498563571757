import { createSlice } from '@reduxjs/toolkit';
import {
    asyncFetchPositions,
    asyncFetchTags,
    asyncPatchPosition,
    asyncCreatePosition,
    asyncDeletePosition,
    asyncCreateVariant,
    asyncCreateTag,
    asyncDeleteTag,
    asyncDeleteVariant,
    asyncCreatePurchase,
    asyncDeletePurchase,
} from './thunks';
import { IPositionsState } from './types';
import {
    addPosition,
    addPurchase,
    addTag,
    addVariant,
    deletePosition,
    patchPosition,
    removePurchase,
    removeTag,
    removeVariant,
    setLoaded,
    setPositions,
    setTags,
} from './reducer';

const initialState: IPositionsState = {
    positions: [],
    tags: [],
    loaded: false,
};

export const reducers = {};

const slice = createSlice({
    name: 'positions',
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(asyncFetchPositions.fulfilled, setPositions);
        builder.addCase(asyncFetchPositions.rejected, setLoaded);
        builder.addCase(asyncFetchTags.fulfilled, setTags);
        builder.addCase(asyncPatchPosition.fulfilled, patchPosition);
        builder.addCase(asyncCreatePosition.fulfilled, addPosition);
        builder.addCase(asyncDeletePosition.fulfilled, deletePosition);
        builder.addCase(asyncCreateVariant.fulfilled, addVariant);
        builder.addCase(asyncDeleteVariant.fulfilled, removeVariant);
        builder.addCase(asyncCreateTag.fulfilled, addTag);
        builder.addCase(asyncDeleteTag.fulfilled, removeTag);
        builder.addCase(asyncCreatePurchase.fulfilled, addPurchase);
        builder.addCase(asyncDeletePurchase.fulfilled, removePurchase);
    },
});

export default slice.reducer;
