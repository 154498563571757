import { createSlice } from '@reduxjs/toolkit';
import { asyncFetchUser } from './thunks';
import { IUsersState } from './types';
import { setUser } from './reducer';

const initialState: IUsersState = {};

export const reducers = {};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(asyncFetchUser.fulfilled, setUser);
    },
});

export default slice.reducer;
