import { IPosition } from 'store/modules/positions/types';

export function isNanToUndefined(value: number | typeof NaN): number | null {
    if (Number.isNaN(value)) return null;
    return value;
}

const getMinMaxPrice = (position: IPosition): { min: number; max: number } => {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;

    position.variants.forEach(variant => {
        if (!variant.price) return;
        const sum = variant.price * (Number(variant.count) || 1);
        if (sum < min) min = sum;
        if (sum > max) max = sum;
    });
    if (min === Number.MAX_SAFE_INTEGER) min = 0;
    else if (min < position.spentPrice) min = position.spentPrice;
    return { min, max };
};

const getRealMinMaxPrice = (
    position: IPosition
): { min: number; max: number } => {
    let price = 0;
    const { min, max } = getMinMaxPrice(position);
    if (position.price) price = position.price * (Number(position.count) || 1);
    const notNullMin = min || price;
    const notNullMax = max || price;
    return { min: notNullMin, max: notNullMax };
};

const getSpentPrice = (position: IPosition): number => {
    if (!position.purchases.length) return 0;
    return position.purchases.reduce(
        (acc, purchase) => acc + Number(purchase.price),
        0
    );
};

const getPlanDifference = (
    position: IPosition,
    actualPrice: number
): number => {
    const { max } = getRealMinMaxPrice(position);
    const spent = getSpentPrice(position);
    if (spent > actualPrice) return spent - actualPrice;
    if (!position.completed) return 0;
    return spent - max;
};

export const calculatePosition = (position: IPosition): IPosition => {
    const { min, max } = getMinMaxPrice(position);
    let actualPrice: number;
    const spentPrice = getSpentPrice(position);

    if (position.completed) actualPrice = spentPrice;
    else if (position.price)
        actualPrice = position.price * (Number(position.count) || 1);
    else actualPrice = min;

    const difference = getPlanDifference(position, actualPrice);
    if (spentPrice > actualPrice) actualPrice = spentPrice;
    return {
        ...position,
        spentPrice,
        min,
        max,
        actualPrice,
        difference,
    };
};

export const currencyLocaleOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'RUB',
};
