import React from 'react';
import './CategoryList.scss';
import { Space } from 'antd';
import CategoryHeader from 'components/CategoryHeader';
import PositionList from 'containers/mainPage/PositionList';
import { IBaseCategory, ICategory } from 'store/modules/categories/types';
import { IBasePosition } from 'store/modules/positions/types';
import { useAppDispatch } from 'store';
import {
    asyncCreateCategory,
    asyncDeleteCategory,
    asyncPatchCategory,
} from 'store/modules/categories/thunk';
import { Id } from 'js/types';
import NewPositionCard from 'components/PositionCard/NewPositionCard';
import { asyncCreatePosition } from 'store/modules/positions/thunks';
import NewCategoryHeader from 'components/CategoryHeader/NewCategoryHeader';
import NewCategoryBlock from 'containers/mainPage/CategoryList/NewCategoryBlock';
import { getPositionByCategory } from './utils';
import { CategorySectionProps } from './types';

export default function CategorySection({
    category,
    positions,
}: CategorySectionProps): React.ReactElement {
    const dispatch = useAppDispatch();

    const [isCreatingPosition, setIsCreatingPosition] = React.useState(false);
    const [isCreatingCategory, setIsCreatingCategory] = React.useState(false);

    const handleEditCategory = (baseCategory: Partial<IBaseCategory> & Id) => {
        dispatch(asyncPatchCategory(baseCategory));
    };

    const handleCreatePosition = async (position: IBasePosition) =>
        dispatch(asyncCreatePosition(position))
            .unwrap()
            .then(() => {
                setIsCreatingPosition(false);
            });

    const handleCreateCategory = async (category_: IBaseCategory) => {
        dispatch(asyncCreateCategory(category_))
            .unwrap()
            .then(() => {
                setIsCreatingCategory(false);
            });
    };

    const handleDeleteCategory = async (category_: ICategory) => {
        dispatch(asyncDeleteCategory(category_));
    };

    return (
        <Space direction="vertical" className="block" size="large">
            {category && (
                <CategoryHeader
                    positions={getPositionByCategory(positions, category)}
                    onPositionCreate={() => setIsCreatingPosition(true)}
                    onCategoryEdit={handleEditCategory}
                    onCategoryDelete={handleDeleteCategory}
                    category={category}
                />
            )}
            {isCreatingCategory && (
                <NewCategoryHeader
                    onCreate={handleCreateCategory}
                    onCancel={() => setIsCreatingCategory(false)}
                />
            )}
            {!category && (
                <NewCategoryBlock
                    positions={getPositionByCategory(positions, category)}
                    setIsCreatingPosition={setIsCreatingPosition}
                    setIsCreatingCategory={setIsCreatingCategory}
                />
            )}
            {isCreatingPosition && (
                <NewPositionCard
                    category={category}
                    onCreate={handleCreatePosition}
                    onCancel={() => setIsCreatingPosition(false)}
                />
            )}
            <PositionList
                positions={getPositionByCategory(positions, category)}
            />
        </Space>
    );
}
