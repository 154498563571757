import React from 'react';
import './CategoryHeader.scss';
import { Button, Col, Dropdown, Input, MenuProps, Row, Space } from 'antd';
import { DeleteOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import EmojiPicker from 'components/ui/EmojiPicker';
import { IBaseCategory } from 'store/modules/categories/types';
import useUpdateFields from 'js/hooks/useUpdateFields';
import { CategoryHeaderProps } from './types';

interface IField {
    [key: string]: {
        fontSize: string;
        placeholder: string;
    };
}

const fieldsMap: IField = {
    name: {
        fontSize: '2rem',
        placeholder: 'Название',
    },
    description: {
        fontSize: '1rem',
        placeholder: 'Описание',
    },
};

function CategoryHeader({
    onPositionCreate = () => {},
    category,
    positions = [],
    onCategoryEdit = () => {},
    onCategoryDelete = () => {},
}: CategoryHeaderProps): React.ReactElement {
    const categoryForm = React.useRef<IBaseCategory>({
        name: category.name,
        emoji: category.emoji,
        description: category.description,
    });

    const items: MenuProps['items'] = React.useMemo(() => {
        const newItems: MenuProps['items'] = [
            {
                key: 'positionAdd',
                icon: <PlusOutlined />,
                onClick: onPositionCreate,
                label: 'Добавить позицию',
            },
        ];
        if (!positions.length) {
            newItems.push({
                key: 'delete',
                label: 'Удалить',
                icon: <DeleteOutlined className="red" />,
                onClick: () => onCategoryDelete(category),
            });
        }
        return newItems;
    }, [category, onCategoryDelete, onPositionCreate, positions.length]);

    const { handleUpdateField, handleTimeoutUpdateField } = useUpdateFields(
        categoryForm,
        onCategoryEdit,
        category
    );
    return (
        <Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
            <Col span={22}>
                <Space>
                    <EmojiPicker
                        name="emoji"
                        defaultValue={categoryForm.current.emoji}
                        onEmojiSelect={handleUpdateField}
                        style={{ fontSize: '2rem' }}
                    />
                    <Space direction="vertical" size="small" className="block">
                        {(
                            ['name', 'description'] as (keyof IBaseCategory)[]
                        ).map(field => (
                            <Input
                                key={field}
                                className="hoverable-input"
                                style={{
                                    fontSize: fieldsMap[field].fontSize,
                                    width: '800px',
                                }}
                                name={field}
                                defaultValue={categoryForm.current[field]}
                                onChange={handleTimeoutUpdateField}
                                placeholder={fieldsMap[field].placeholder}
                            />
                        ))}
                    </Space>
                </Space>
            </Col>
            <Col span={2}>
                <Dropdown menu={{ items }}>
                    <Button type="text" icon={<MoreOutlined />} />
                </Dropdown>
            </Col>
        </Row>
    );
}

CategoryHeader.defaultProps = {
    onPositionCreate: undefined,
};

export default CategoryHeader;
