import React from 'react';
import './LoginPage.scss';
import { Button, Card, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { asyncLoginForm } from 'store/modules/auth/thunks';
import { useAppDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import { getToken, removeToken } from 'js/apiClient';
import { asyncFetchUser } from 'store/modules/users/thunks';

function LoginPage(): React.ReactElement {
    const [form] = useForm();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!getToken('token')) return;
        dispatch(asyncFetchUser())
            .unwrap()
            .then(() => navigate('/'))
            .catch(() => removeToken('token'));
    }, [dispatch, navigate]);

    const handleFinish = async (values: {
        email: string;
        password: string;
    }) => {
        dispatch(asyncLoginForm(values))
            .unwrap()
            .then(() => navigate('/'))
            .catch(() => {
                form.setFields([
                    {
                        name: 'password',
                        errors: ['Неверный логин или пароль'],
                    },
                    {
                        name: 'email',
                        errors: ['Неверный логин или пароль'],
                    },
                ]);
            });
    };

    return (
        <main className="login-page">
            <Card title="Авторизация" style={{ width: '400px' }}>
                <Form
                    form={form}
                    onFinish={e => handleFinish(e)}
                    layout="vertical"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Неверный формат email',
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Пароль"
                        rules={[{ required: true, message: 'Введите пароль' }]}
                    >
                        <Input type="password" />
                    </Form.Item>
                    <Button htmlType="submit">Войти</Button>
                </Form>
            </Card>
        </main>
    );
}

export default LoginPage;
