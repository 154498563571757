import React from 'react';
import './MenuBlock.scss';
import { Button, Dropdown, MenuProps } from 'antd';
import {
    AppstoreOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    MoreOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { MenuBlockProps } from './types';

function MenuBlock({
    position,
    onPurchasesClick = () => {},
    onVariantsClick = () => {},
    onCompleteClick = () => {},
    onDeleteClick = () => {},
}: MenuBlockProps): React.ReactElement {
    const [completed, setCompleted] = React.useState(position.completed);

    const handleCompletedChange = React.useCallback(() => {
        setCompleted(value => {
            onCompleteClick({ target: { name: 'completed', value: !value } });
            return !value;
        });
    }, [onCompleteClick]);

    const items: MenuProps['items'] = React.useMemo(
        () => [
            {
                key: 'purchases',
                onClick: () => onPurchasesClick(position),
                icon: <WalletOutlined />,
                label: 'Покупки',
            },
            {
                key: 'variants',
                onClick: () => onVariantsClick(position),
                icon: <AppstoreOutlined />,
                label: 'Варианты',
            },
            {
                key: 'checkbox',
                onClick: handleCompletedChange,
                icon: completed ? (
                    <CloseOutlined className="red" />
                ) : (
                    <CheckOutlined className="green" />
                ),
                label: completed ? 'Не куплено' : 'Куплено',
            },
            {
                key: 'delete',
                onClick: () => onDeleteClick(position),
                icon: <DeleteOutlined className="red" />,
                label: 'Удалить',
            },
        ],
        [
            completed,
            handleCompletedChange,
            onDeleteClick,
            onPurchasesClick,
            onVariantsClick,
            position,
        ]
    );

    return (
        <Dropdown menu={{ items }}>
            <Button type="text">
                <MoreOutlined />
            </Button>
        </Dropdown>
    );
}

export default MenuBlock;
