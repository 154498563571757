import { RouteObject } from 'react-router-dom';
import React from 'react';
import MainPage from 'pages/MainPage';
import LoginPage from 'pages/login/LoginPage';
import RefreshPage from 'pages/refresh/RefreshPage';

const routes: RouteObject[] = [
    {
        path: '/',
        element: <MainPage />,
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/refresh',
        element: <RefreshPage />,
    },
];

export default routes;
