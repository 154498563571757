import { createSlice } from '@reduxjs/toolkit';
import {
    asyncCreateCategory,
    asyncDeleteCategory,
    asyncFetchCategories,
    asyncPatchCategory,
} from 'store/modules/categories/thunk';
import {
    addCategory,
    patchCategory,
    removeCategory,
    setCategories,
    setLoaded,
} from 'store/modules/categories/reducers';
import { ICategoriesState } from './types';

const initialState: ICategoriesState = {
    categories: [],
    loaded: false,
};

export const reducers = {};

const slice = createSlice({
    name: 'categories',
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(asyncFetchCategories.fulfilled, setCategories);
        builder.addCase(asyncFetchCategories.rejected, setLoaded);
        builder.addCase(asyncPatchCategory.fulfilled, patchCategory);
        builder.addCase(asyncCreateCategory.fulfilled, addCategory);
        builder.addCase(asyncDeleteCategory.fulfilled, removeCategory);
    },
});

export default slice.reducer;
