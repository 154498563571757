import React from 'react';
import './PurchaseCard.scss';
import { Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import { currencyLocaleOptions } from 'js/utils';
import { PurchaseCardProps } from './types';

function PurchaseCard({
    purchase,
    onDelete = () => {},
}: PurchaseCardProps): React.ReactElement {
    return (
        <div className="purchase-card">
            <div>{dayjs(purchase.dateCreated).format('DD MMMM YYYY')}</div>
            <div>
                {parseFloat(purchase.price).toLocaleString(
                    'ru',
                    currencyLocaleOptions
                )}
            </div>
            <Tooltip title="Удалить">
                <Button
                    onClick={() => onDelete(purchase)}
                    type="text"
                    icon={<DeleteOutlined className="red" />}
                />
            </Tooltip>
        </div>
    );
}

export default PurchaseCard;
