import React from 'react';
import './PurchasesModal.scss';
import { Button, Card, Form, InputNumber, Modal, Space } from 'antd';
import PurchaseCard from 'components/PurchaseCard';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
    IBasePurchase,
    IPosition,
    IPurchase,
} from 'store/modules/positions/types';
import { useAppDispatch } from 'store';
import {
    asyncCreatePurchase,
    asyncDeletePurchase,
} from 'store/modules/positions/thunks';
import { PurchasesModalProps } from './types';

function NewPurchase({
    position,
    onCancel,
}: {
    onCancel: () => void;
    position: IPosition;
}): React.ReactElement {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<IBasePurchase>();

    const handleConfirm = async (e: IBasePurchase) => {
        await dispatch(
            asyncCreatePurchase({ ...e, positionId: position.id })
        ).unwrap();
        onCancel();
    };

    return (
        <Form layout="vertical" form={form} onFinish={handleConfirm}>
            <Card
                actions={[
                    <Button
                        block
                        key="add"
                        type="text"
                        htmlType="submit"
                        icon={<PlusOutlined className="green" />}
                    />,
                    <Button
                        block
                        key="cancel"
                        type="text"
                        icon={<CloseOutlined className="red" />}
                        onClick={onCancel}
                    />,
                ]}
            >
                <Form.Item
                    label="Стоимость"
                    name="price"
                    rules={[{ required: true }]}
                >
                    <InputNumber placeholder="Стоимость" addonAfter="₽" />
                </Form.Item>
            </Card>
        </Form>
    );
}

function PurchasesModal({
    open,
    onCancel,
    position,
}: PurchasesModalProps): React.ReactElement {
    const dispatch = useAppDispatch();

    const [adding, setAdding] = React.useState(false);

    const handleDelete = (purchase: IPurchase) => {
        dispatch(asyncDeletePurchase(purchase));
    };

    return (
        <Modal footer={null} title="Оплаты" open={open} onCancel={onCancel}>
            <Space direction="vertical" className="block">
                {position.purchases.map(purchase => (
                    <PurchaseCard
                        onDelete={handleDelete}
                        key={purchase.id}
                        purchase={purchase}
                    />
                ))}
                {adding && (
                    <NewPurchase
                        position={position}
                        onCancel={() => setAdding(false)}
                    />
                )}
                {!adding && (
                    <Button
                        block
                        icon={<PlusOutlined />}
                        onClick={() => setAdding(true)}
                    >
                        Добавить
                    </Button>
                )}
            </Space>
        </Modal>
    );
}

export default PurchasesModal;
