import { IPosition } from 'store/modules/positions/types';
import { ICategory } from 'store/modules/categories/types';

export function getPositionByCategory(
    positions: IPosition[],
    category: ICategory | undefined = undefined
): IPosition[] {
    return positions.filter(
        position => position.categoryId === (category?.id || null)
    );
}
