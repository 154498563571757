import { createAsyncThunk } from '@reduxjs/toolkit';
import getApiClient from 'js/apiClient';
import { AxiosResponse } from 'axios';
import { Id } from 'js/types';
import {
    IBasePosition,
    IBasePurchase,
    INewTag,
    INewVariant,
    IPosition,
    IPositionsQuery,
    IPositionTag,
    IPurchase,
    ITag,
    IVariant,
    IVariantFields,
} from './types';

export const asyncFetchPositions = createAsyncThunk(
    'positions/fetchPositions',
    async (query: IPositionsQuery, { rejectWithValue }) =>
        getApiClient('token')
            .get('positions/', { params: { ...query } })
            .then((response: AxiosResponse<IPosition[]>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncPatchPosition = createAsyncThunk(
    'positions/patchPosition',
    async ({ id, ...data }: Partial<IBasePosition> & Id, { rejectWithValue }) =>
        getApiClient('token')
            .patch(`positions/${id}/`, data)
            .then((response: AxiosResponse<IPosition>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncDeletePosition = createAsyncThunk(
    'positions/deletePosition',
    async ({ id }: Id, { rejectWithValue }) =>
        getApiClient('token')
            .delete(`positions/${id}/`)
            .then(() => ({
                id,
            }))
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncCreatePosition = createAsyncThunk(
    'positions/createPosition',
    async (data: IBasePosition, { rejectWithValue }) =>
        getApiClient('token')
            .post(`positions/`, { ...data })
            .then((response: AxiosResponse<IPosition>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncFetchTags = createAsyncThunk(
    'positions/fetchTags',
    async (_, { rejectWithValue }) =>
        getApiClient('token')
            .get('positions/tags/')
            .then((response: AxiosResponse<ITag[]>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncFetchVariantFields = createAsyncThunk(
    'positions/fetchVariantFields',
    async (url: string, { rejectWithValue }) =>
        getApiClient('token')
            .post('positions/variants/fields/', { url })
            .then((response: AxiosResponse<IVariantFields>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncCreateVariant = createAsyncThunk(
    'positions/createVariant',
    async (data: INewVariant, { rejectWithValue }) =>
        getApiClient('token')
            .post('positions/variants/', data)
            .then((response: AxiosResponse<IVariant>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncDeleteVariant = createAsyncThunk(
    'positions/deleteVariant',
    async (variant: IVariant, { rejectWithValue }) =>
        getApiClient('token')
            .delete(`positions/variants/${variant.id}/`)
            .then(() => variant)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncDeleteTag = createAsyncThunk(
    'positions/deleteTag',
    async ({ positionId, id, ...others }: IPositionTag, { rejectWithValue }) =>
        getApiClient('token')
            .delete(`positions/${positionId}/tags/${id}/`)
            .then(() => ({ positionId, ...others } as IPositionTag))
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncCreateTag = createAsyncThunk(
    'positions/createTag',
    async ({ positionId, ...others }: INewTag, { rejectWithValue }) =>
        getApiClient('token')
            .post(`positions/${positionId}/tags/`, others)
            .then(
                (response: AxiosResponse<ITag>) =>
                    ({
                        ...response.data,
                        positionId,
                    } as IPositionTag)
            )
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncCreatePurchase = createAsyncThunk(
    'positions/addPurchase',
    async (purchase: IBasePurchase, { rejectWithValue }) =>
        getApiClient('token')
            .post('purchases/', { ...purchase })
            .then((response: AxiosResponse<IPurchase>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncDeletePurchase = createAsyncThunk(
    'positions/deletePurchase',
    async (purchase: IPurchase, { rejectWithValue }) =>
        getApiClient('token')
            .delete(`purchases/${purchase.id}/`)
            .then(() => purchase)
            .catch(err => rejectWithValue(err.response?.data))
);
