import React from 'react';
import './PositionCard.scss';
import { Card, Col, Input, Row, Space, Tooltip } from 'antd';
import EmojiPicker from 'components/ui/EmojiPicker';
import TagSelect from 'components/ui/TagSelect';
import { searchTag } from 'components/ui/TagSelect/TagSelect';
import { currencyLocaleOptions } from 'js/utils';
import {
    IBasePosition,
    IBaseTag,
    IPosition,
    ITag,
} from 'store/modules/positions/types';
import { useAppSelector } from 'store';
import useUpdateFields from 'js/hooks/useUpdateFields';
import { CheckOutlined } from '@ant-design/icons';
import VariantsModal from 'containers/mainPage/modals/VariantsModal';
import PurchasesModal from 'containers/mainPage/modals/PurchasesModal';
import { PositionCardProps } from './types';
import CalculateBlock from './CalculateBlock';
import MenuBlock from './MenuBlock';

const getPaymentsSum = (position: IPosition): number => {
    let sum = 0.0;
    position.purchases?.forEach(purchase => {
        sum += Number(purchase.price);
    });
    return sum;
};

function PositionCard({
    position,
    onFieldChange = () => {},
    onDeleteClick = () => {},
    onTagAdd = () => {},
    onTagRemove = () => {},
}: PositionCardProps): React.ReactElement {
    const { tags } = useAppSelector(state => state.positions);
    const positionForm = React.useRef<IBasePosition>({
        name: position.name,
        emoji: position.emoji,
        price: position.price,
        count: position.count,
        completed: position.completed,
        categoryId: position.categoryId,
        description: position.description,
    });
    const positionTags = React.useRef<ITag[]>(position.tags);

    const handleTagChange = async (value: string[]) => {
        const tempTags: (ITag | IBaseTag)[] = value.map(tagName =>
            searchTag(tagName, tags)
        );
        await Promise.all(
            tempTags.map(async tag => {
                if (!positionTags.current.find(t => t.name === tag.name)) {
                    await onTagAdd({ positionId: position.id, ...tag });
                }
            })
        );
        await Promise.all(
            positionTags.current.map(async tag => {
                if (!tempTags.find(t => t.name === tag.name)) {
                    await onTagRemove({ positionId: position.id, ...tag });
                }
            })
        );
    };

    const { handleUpdateField, handleTimeoutUpdateField } = useUpdateFields(
        positionForm,
        onFieldChange,
        position
    );

    const [variantsVisible, setVariantsVisible] = React.useState(false);
    const [purchasesVisible, setPurchasesVisible] = React.useState(false);

    return (
        <>
            <VariantsModal
                position={position}
                open={variantsVisible}
                onCancel={() => setVariantsVisible(false)}
            />
            <PurchasesModal
                position={position}
                open={purchasesVisible}
                onCancel={() => setPurchasesVisible(false)}
            />
            <Card className="block position-card" size="small">
                <Row className="block" align="middle">
                    <Col span={1} className="center">
                        <EmojiPicker
                            name="emoji"
                            defaultValue={positionForm.current.emoji}
                            onEmojiSelect={handleUpdateField}
                        />
                    </Col>
                    <Col span={4}>
                        <Space direction="vertical">
                            <Input
                                className="hoverable-input block"
                                name="name"
                                defaultValue={positionForm.current.name}
                                onChange={handleTimeoutUpdateField}
                                placeholder="Название"
                            />
                            <Input
                                className="hoverable-input block"
                                name="description"
                                defaultValue={positionForm.current.description}
                                onChange={handleTimeoutUpdateField}
                                placeholder="Описание"
                            />
                        </Space>
                    </Col>
                    <Col span={5}>
                        <TagSelect
                            className="hoverable-input"
                            defaultValue={
                                positionTags.current.map(tag => tag.name) || []
                            }
                            onChange={handleTagChange}
                        />
                    </Col>
                    <Col span={7}>
                        <CalculateBlock
                            names={['price', 'count']}
                            position={position}
                            onChange={handleTimeoutUpdateField}
                        />
                    </Col>
                    <Col
                        span={4}
                        className="center hover"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setVariantsVisible(true)}
                    >
                        <Tooltip title="Цена по вариантам">
                            {!!position.min &&
                                position.min !== position.max &&
                                `${position.min.toLocaleString(
                                    'ru',
                                    currencyLocaleOptions
                                )} – `}
                            {!!position.max &&
                                `${position.max.toLocaleString(
                                    'ru',
                                    currencyLocaleOptions
                                )}`}
                        </Tooltip>
                    </Col>
                    <Col
                        span={2}
                        className="center hover"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setPurchasesVisible(true)}
                    >
                        <Space direction="vertical">
                            <Space>
                                <Tooltip title="Сумма оплаты">
                                    {getPaymentsSum(position).toLocaleString(
                                        'ru',
                                        currencyLocaleOptions
                                    )}
                                </Tooltip>
                                {position.completed && (
                                    <Tooltip title="Позиция выкуплена">
                                        <CheckOutlined
                                            style={{ color: 'green' }}
                                        />
                                    </Tooltip>
                                )}
                            </Space>
                            {!!position.difference && (
                                <div
                                    style={{
                                        color:
                                            position.difference < 0
                                                ? 'green'
                                                : 'red',
                                    }}
                                >
                                    {(position.difference * -1).toLocaleString(
                                        'ru',
                                        currencyLocaleOptions
                                    )}
                                </div>
                            )}
                        </Space>
                    </Col>
                    <Col span={1}>
                        <MenuBlock
                            position={position}
                            onPurchasesClick={() => setPurchasesVisible(true)}
                            onVariantsClick={() => setVariantsVisible(true)}
                            onCompleteClick={handleUpdateField}
                            onDeleteClick={onDeleteClick}
                        />
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default PositionCard;
