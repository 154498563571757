import React from 'react';
import './RefreshPage.scss';
import { useAppDispatch } from 'store';
import { getToken, removeToken } from 'js/apiClient';
import { useNavigate } from 'react-router-dom';
import { asyncRefreshToken } from 'store/modules/auth/thunks';

function RefreshPage(): React.ReactElement {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        const token = getToken('refreshToken');
        if (!token) {
            navigate('/login');
            return;
        }
        const searchParams = new URLSearchParams(window.location.search);
        dispatch(asyncRefreshToken(token))
            .unwrap()
            .then(() => navigate(searchParams.get('next') || '/'))
            .catch(() => {
                removeToken('token');
                removeToken('refreshToken');
                navigate('/login');
            });
    }, [dispatch, navigate]);

    return <div> Loading... </div>;
}

export default RefreshPage;
