import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import usersReducer from './modules/users';
import authReducer from './modules/auth';
import positionsReducer from './modules/positions';
import categoriesReducer from './modules/categories';

const store = configureStore({
    reducer: {
        users: usersReducer,
        auth: authReducer,
        positions: positionsReducer,
        categories: categoriesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
