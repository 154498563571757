import React from 'react';
import './CategoryList.scss';
import { Skeleton, Space } from 'antd';
import CategorySection from 'containers/mainPage/CategoryList/CategorySection';
import { useAppSelector } from 'store';
import { getPositionByCategory } from './utils';

function CategoryList(): React.ReactElement {
    const { positions, loaded: positionsLoaded } = useAppSelector(
        state => state.positions
    );
    const { categories, loaded: categoriesLoaded } = useAppSelector(
        state => state.categories
    );

    if (!categoriesLoaded || !positionsLoaded)
        return (
            <>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </>
        );

    return (
        <Space direction="vertical" className="block" size="large">
            {categories.map(category => (
                <CategorySection
                    key={category.id}
                    category={category}
                    positions={getPositionByCategory(positions, category)}
                />
            ))}

            <CategorySection positions={getPositionByCategory(positions)} />
        </Space>
    );
}

export default CategoryList;
