import { IPayloadAction } from 'store/type';
import { Id } from 'js/types';
import { calculatePosition } from 'js/utils';
import {
    IPosition,
    IPositionsState,
    IPositionTag,
    IPurchase,
    ITag,
    IVariant,
} from './types';

export function setPositions(
    state: IPositionsState,
    action: IPayloadAction<IPosition[]>
): IPositionsState {
    return {
        ...state,
        positions: action.payload.map(calculatePosition),
        loaded: true,
    };
}

export function setLoaded(
    state: IPositionsState,
    _: IPayloadAction<unknown>
): IPositionsState {
    return {
        ...state,
        loaded: true,
    };
}

export function addPosition(
    state: IPositionsState,
    action: IPayloadAction<IPosition>
): IPositionsState {
    return {
        ...state,
        positions: [...state.positions, calculatePosition(action.payload)],
    };
}

export function setTags(
    state: IPositionsState,
    action: IPayloadAction<ITag[]>
): IPositionsState {
    return {
        ...state,
        tags: action.payload,
    };
}

export function patchPosition(
    state: IPositionsState,
    action: IPayloadAction<IPosition>
): IPositionsState {
    return {
        ...state,
        positions: state.positions.map(position =>
            position.id === action.payload.id ? action.payload : position
        ),
    };
}

export function deletePosition(
    state: IPositionsState,
    action: IPayloadAction<Id>
): IPositionsState {
    return {
        ...state,
        positions: state.positions.filter(
            position => position.id !== action.payload.id
        ),
    };
}

export function addTag(
    state: IPositionsState,
    action: IPayloadAction<IPositionTag>
): IPositionsState {
    const newTags = [...state.tags];
    if (!newTags.find(tag => tag.name === action.payload.name))
        newTags.push(action.payload);
    return {
        ...state,
        tags: newTags,
    };
}

export function removeTag(
    state: IPositionsState,
    _: IPayloadAction<IPositionTag>
): IPositionsState {
    return {
        ...state,
    };
}

export function addVariant(
    state: IPositionsState,
    action: IPayloadAction<IVariant>
): IPositionsState {
    return {
        ...state,
        positions: state.positions.map(position => {
            if (position.id === action.payload.position) {
                return {
                    ...position,
                    variants: [...position.variants, action.payload],
                };
            }
            return position;
        }),
    };
}

export function removeVariant(
    state: IPositionsState,
    action: IPayloadAction<IVariant>
): IPositionsState {
    return {
        ...state,
        positions: state.positions.map(position => {
            if (position.id === action.payload.position) {
                return {
                    ...position,
                    variants: position.variants.filter(
                        variant => variant.id !== action.payload.id
                    ),
                };
            }
            return position;
        }),
    };
}

export function addPurchase(
    state: IPositionsState,
    action: IPayloadAction<IPurchase>
): IPositionsState {
    return {
        ...state,
        positions: state.positions.map(position => {
            if (position.id === action.payload.positionId) {
                return {
                    ...position,
                    purchases: [...position.purchases, action.payload],
                };
            }
            return position;
        }),
    };
}

export function removePurchase(
    state: IPositionsState,
    action: IPayloadAction<IPurchase>
): IPositionsState {
    return {
        ...state,
        positions: state.positions.map(position => {
            if (position.id === action.payload.positionId) {
                return {
                    ...position,
                    purchases: position.purchases.filter(
                        purchase => purchase.id !== action.payload.id
                    ),
                };
            }
            return position;
        }),
    };
}
