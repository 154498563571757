import React from 'react';
import './MainLayout.scss';
import { Layout, Space, Typography } from 'antd';
import { getToken } from 'js/apiClient';
import { asyncFetchUser } from 'store/modules/users/thunks';
import { useAppDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import { useUnauthorized } from 'js/hooks';
import { ReactComponent as Logo } from 'assets/sign.svg';
import { MainLayoutProps } from './types';

const { Title } = Typography;
const { Header } = Layout;

function MainLayout({
    children,
    className,
}: MainLayoutProps): React.ReactElement {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useUnauthorized();

    React.useEffect(() => {
        if (!getToken('token')) navigate('/login');
        dispatch(asyncFetchUser());
    }, [dispatch, navigate]);

    return (
        <Layout>
            <Space direction="vertical" className={className}>
                <Header>
                    <div className="header-grid">
                        <Logo />
                        <Title level={3}>Баблинский</Title>
                    </div>
                </Header>
                {children}
            </Space>
        </Layout>
    );
}

export default MainLayout;
