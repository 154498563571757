import { createSlice } from '@reduxjs/toolkit';

export const reducers = {};

const slice = createSlice({
    name: 'auth',
    initialState: {},
    reducers,
    extraReducers: builder => {},
});

export default slice.reducer;
