import React from 'react';
import './CategoryList.scss';
import { Button, Col, Row, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { NewCategoryBlockProps } from 'components/CategoryHeader/types';

const { Title } = Typography;

export default function NewCategoryBlock({
    positions,
    setIsCreatingCategory = () => {},
    setIsCreatingPosition = () => {},
}: NewCategoryBlockProps): React.ReactElement {
    return (
        <Row className="block" align="middle">
            <Col span={16}>
                {!!positions.length && <Title>Остальное</Title>}
            </Col>
            <Col span={8}>
                <Space
                    className="block"
                    style={{
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        block
                        icon={<PlusOutlined />}
                        onClick={() => setIsCreatingCategory(true)}
                    >
                        Добавить категорию
                    </Button>
                    <Button
                        block
                        icon={<PlusOutlined />}
                        onClick={() => setIsCreatingPosition(true)}
                    >
                        Добавить позицию
                    </Button>
                </Space>
            </Col>
        </Row>
    );
}
