import React from 'react';
import './PositionList.scss';
import PositionCard from 'components/PositionCard';
import {
    IBasePosition,
    INewTag,
    IPosition,
    IPositionTag,
} from 'store/modules/positions/types';
import { Id } from 'js/types';
import { useAppDispatch } from 'store';
import {
    asyncCreateTag,
    asyncDeletePosition,
    asyncDeleteTag,
    asyncPatchPosition,
} from 'store/modules/positions/thunks';
import { Space } from 'antd';
import { PositionListProps } from './types';

function PositionList({ positions }: PositionListProps): React.ReactElement {
    const dispatch = useAppDispatch();

    const handleFieldChange = (position: Partial<IBasePosition> & Id) => {
        dispatch(asyncPatchPosition(position));
    };

    const handleDeletePosition = (position: IPosition) => {
        dispatch(asyncDeletePosition(position));
    };

    const handleNewTag = (tag: INewTag) => {
        dispatch(asyncCreateTag(tag));
    };

    const handleTagRemove = (tag: IPositionTag) => {
        dispatch(asyncDeleteTag(tag));
    };

    return (
        <Space direction="vertical" className="block">
            {positions.map(position => (
                <PositionCard
                    onFieldChange={handleFieldChange}
                    key={position.id}
                    position={position}
                    onDeleteClick={handleDeletePosition}
                    onTagAdd={handleNewTag}
                    onTagRemove={handleTagRemove}
                />
            ))}
        </Space>
    );
}

export default PositionList;
