import React from 'react';
import './CategoryHeader.scss';
import { Button, Col, Input, Row, Space } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import EmojiPicker from 'components/ui/EmojiPicker';
import { IBaseCategory } from 'store/modules/categories/types';
import useUpdateFields from 'js/hooks/useUpdateFields';
import { NewCategoryHeaderProps } from './types';

export default function NewCategoryHeader({
    onCreate = () => {},
    onCancel = () => {},
}: NewCategoryHeaderProps): React.ReactElement {
    const categoryForm = React.useRef<IBaseCategory>({
        name: '',
        emoji: '',
        description: '',
    });

    const { handleUpdateField } = useUpdateFields(categoryForm);

    return (
        <Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
            <Col span={19}>
                <Space>
                    <EmojiPicker
                        name="emoji"
                        defaultValue={categoryForm.current.emoji}
                        onEmojiSelect={handleUpdateField}
                        style={{ fontSize: '2rem' }}
                    />
                    <Space direction="vertical" size="small" className="block">
                        {(
                            ['name', 'description'] as (keyof IBaseCategory)[]
                        ).map(field => (
                            <Input
                                key={field}
                                className="hoverable-input"
                                style={{
                                    fontSize:
                                        field === 'name' ? '2.5rem' : '1rem',
                                    width: '800px',
                                }}
                                name={field}
                                defaultValue={categoryForm.current[field]}
                                onChange={handleUpdateField}
                                placeholder="Название"
                            />
                        ))}
                    </Space>
                </Space>
            </Col>
            <Col span={5}>
                <Space className="block" style={{ justifyContent: 'end' }}>
                    <Button
                        type="text"
                        className="block"
                        icon={<CloseOutlined className="red" />}
                        onClick={onCancel}
                    />
                    <Button
                        type="text"
                        className="block"
                        icon={<PlusOutlined className="green" />}
                        onClick={() => onCreate(categoryForm.current)}
                    />
                </Space>
            </Col>
        </Row>
    );
}
