import { IPayloadAction } from 'store/type';
import { IUser, IUsersState } from './types';

// eslint-disable-next-line import/prefer-default-export
export function setUser(
    state: IUsersState,
    action: IPayloadAction<IUser>
): IUsersState {
    return {
        ...state,
        user: action.payload,
    };
}
