import React from 'react';
import './TagSelect.scss';
import { Select, Tag } from 'antd';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { IBaseTag, ITag } from 'store/modules/positions/types';
import { useAppSelector } from 'store';
import classNames from 'classnames';
import { TagSelectProps } from './types';

export const searchTag = (tagId: string, tags: ITag[]): ITag | IBaseTag =>
    tags.find(tag => tag.name === tagId) || { name: tagId };

function CustomTag({ label, value }: CustomTagProps): React.ReactElement {
    const { tags } = useAppSelector(state => state.positions);
    const tag = searchTag(value, tags);
    const styles = { border: 'inherit', backgroundColor: 'inherit' };
    if ('color' in tag) {
        styles.border = `1px solid ${tag.color}20`;
        styles.backgroundColor = `${tag.color}20`;
    }
    return <Tag style={styles}>{label}</Tag>;
}

function TagSelect({
    defaultValue,
    onChange,
    size,
    className,
}: TagSelectProps): React.ReactElement {
    const { tags } = useAppSelector(state => state.positions);

    return (
        <Select
            allowClear
            size={size}
            className={classNames('block', className)}
            key="tags"
            placeholder="теги"
            mode="tags"
            defaultValue={defaultValue}
            onChange={onChange}
            tagRender={CustomTag}
        >
            {tags.map(tag => (
                <Select.Option key={tag.name} value={tag.name} label={tag.name}>
                    {tag.name}
                </Select.Option>
            ))}
        </Select>
    );
}

export default TagSelect;
