import React from 'react';
import './CalculateBlock.scss';
import { currencyLocaleOptions, isNanToUndefined } from 'js/utils';
import { Form, Input, Space } from 'antd';
import {
    CalculateBlockProps,
    CalculateFormItemProps,
    ICalculateFormItem,
    IPriceCount,
} from './types';

function getPrice({ price, count }: IPriceCount): number | undefined {
    if (!count || !price) return undefined;
    return count * price;
}

function FormItem({
    name,
    label,
    addonAfter,
    positionForm,
    updateField,
}: CalculateFormItemProps) {
    return (
        <Form.Item key={name} label={label}>
            <Input
                type="number"
                name={name}
                placeholder={label}
                defaultValue={
                    Number(
                        positionForm.current[
                            name as keyof typeof positionForm.current
                        ]
                    ) || undefined
                }
                onChange={updateField}
                addonAfter={addonAfter}
            />
        </Form.Item>
    );
}

function CalculateBlock({ position, onChange, names }: CalculateBlockProps) {
    const positionForm = React.useRef<IPriceCount>({
        count: position.count,
        price: position.price,
    });

    const [price, setPrice] = React.useState(
        getPrice({ count: position.count, price: position.price })
    );

    const updateField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name as keyof typeof positionForm.current;
        positionForm.current[name] = isNanToUndefined(e.target.valueAsNumber);
        onChange({
            target: { name, value: positionForm.current[name] },
        });
        setPrice(
            getPrice({
                count: positionForm.current.count,
                price: positionForm.current.price,
            })
        );
    };

    const fields: ICalculateFormItem[] = [
        { name: names[0], label: 'Цена', addonAfter: 'X' },
        {
            name: names[1],
            label: 'Количество',
            addonAfter: price?.toLocaleString('ru', currencyLocaleOptions) && (
                <span className="semi-bold">
                    {price?.toLocaleString('ru', currencyLocaleOptions)}
                </span>
            ),
        },
    ];

    return (
        <Form layout="vertical">
            <Space.Compact>
                {fields.map(field => (
                    <FormItem
                        key={field.name}
                        label={field.label}
                        name={field.name}
                        addonAfter={field.addonAfter}
                        positionForm={positionForm}
                        updateField={updateField}
                    />
                ))}
            </Space.Compact>
        </Form>
    );
}

export default CalculateBlock;
