import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

export default function useUnauthorized(): void {
    const navigate = useNavigate();
    const locations = useLocation();

    React.useEffect(() => {
        const handleUnauthorized = () =>
            navigate(`/refresh?next=${locations.pathname}`);

        window.addEventListener('unauthorized', handleUnauthorized);

        return () => {
            window.removeEventListener('unauthorized', handleUnauthorized);
        };
    }, [locations.pathname, navigate]);
}
