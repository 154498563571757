import React from 'react';
import './MainPage.scss';
import MainLayout from 'layouts/MainLayout';
import { Layout, Modal, Space } from 'antd';
import MainPageFilters from 'containers/mainPage/MainPageFilters';
import { PositionFilter } from 'containers/mainPage/MainPageFilters/types';

import CategoryList from 'containers/mainPage/CategoryList';
import TotalPrice from 'components/TotalPrice';
import { useAppDispatch, useAppSelector } from 'store';
import {
    asyncFetchPositions,
    asyncFetchTags,
} from 'store/modules/positions/thunks';
import { asyncFetchCategories } from 'store/modules/categories/thunk';
import { IPositionsQuery } from 'store/modules/positions/types';

const { Content } = Layout;

function MainPage(): React.ReactElement {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(asyncFetchPositions({}));
        dispatch(asyncFetchCategories());
        dispatch(asyncFetchTags());
    }, [dispatch]);

    const { positions } = useAppSelector(state => state.positions);

    const onFilterChange = async (filter: PositionFilter) => {
        const query: IPositionsQuery = {
            name: filter.search,
            tags: filter.tags.map(tag => tag.id),
            completed: filter.completed,
        };
        await dispatch(asyncFetchPositions(query)).unwrap();
    };

    const [visible, setVisible] = React.useState(false);

    return (
        <MainLayout>
            <Modal open={visible} onCancel={() => setVisible(false)}>
                <div>123</div>
            </Modal>
            <Content className="main-page">
                <Space direction="vertical" className="block" size="large">
                    <MainPageFilters onFilterChange={onFilterChange} />
                    <TotalPrice positions={positions} />
                    <CategoryList />
                </Space>
            </Content>
        </MainLayout>
    );
}

export default MainPage;
