import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';

dayjs.locale(ru);

const { REACT_APP_SENTRY_DSN_URL, REACT_APP_ENVIRONMENT } = process.env;

if (REACT_APP_ENVIRONMENT !== 'master') {
    console.log(
        `Initialised Sentry ${REACT_APP_SENTRY_DSN_URL} Environment: ${REACT_APP_ENVIRONMENT}`
    );

    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN_URL,
        integrations: [new Integrations.BrowserTracing()],
        environment: REACT_APP_ENVIRONMENT,
        tracesSampleRate: 0.1,
    });
}

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOMClient.createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
