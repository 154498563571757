import { createAsyncThunk } from '@reduxjs/toolkit';
import getApiClient from 'js/apiClient';
import { AxiosResponse } from 'axios';
import { IUser } from './types';

export const asyncFetchUser = createAsyncThunk(
    'users/fetchUser',
    async (_, { rejectWithValue }) =>
        getApiClient('token')
            .get('users/session/')
            .then((response: AxiosResponse<IUser>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);
