import { createAsyncThunk } from '@reduxjs/toolkit';
import getApiClient, { setToken } from 'js/apiClient';
import { AxiosResponse } from 'axios';
import { ILoginForm, ITokens } from './types';

export const asyncLoginForm = createAsyncThunk(
    'auth/loginForm',
    async (form: ILoginForm, { rejectWithValue }) =>
        getApiClient('token')
            .post('users/login/', { ...form })
            .then((response: AxiosResponse<ITokens>) => {
                setToken('token', response.data.token);
                setToken('refreshToken', response.data.refreshToken);

                return response.data;
            })
            .catch(error => rejectWithValue(error.response.data))
);

export const asyncRefreshToken = createAsyncThunk<ITokens, string>(
    'auth/refreshToken',
    async (refreshToken, { rejectWithValue }) =>
        getApiClient('token')
            .post('users/refresh/', { refreshToken })
            .then((response: AxiosResponse<ITokens>) => {
                setToken('token', response.data.token);
                setToken('refreshToken', response.data.refreshToken);

                return response.data;
            })
            .catch(error => rejectWithValue(error.response.data))
);
