import React from 'react';
import './VariantsModal.scss';
import { Avatar, Button, Card, Form, Input, Modal, Space } from 'antd';
import VariantCard from 'components/VariantCard';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'store';
import {
    asyncCreateVariant,
    asyncDeleteVariant,
    asyncFetchVariantFields,
} from 'store/modules/positions/thunks';
import DummyImage from 'assets/dummy.jpg';
import {
    IBaseVariant,
    INewVariant,
    IPosition,
    IVariant,
    IVariantFields,
} from 'store/modules/positions/types';
import TextArea from 'antd/es/input/TextArea';
import { VariantsModalProps } from './types';

const { Search } = Input;

function NewVariant({
    onCancel = () => {},
    position,
}: {
    onCancel: () => void;
    position: IPosition;
}): React.ReactElement {
    const dispatch = useAppDispatch();

    const [form] = Form.useForm<IBaseVariant>();
    const [imageUrl, setImageUrl] = React.useState<string | undefined>(
        undefined
    );
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
    const [countRequired, setCountRequired] = React.useState(false);

    const onConfirm = (values: IBaseVariant) => {
        const data: INewVariant = {
            positionId: position.id,
            imageUrl,
            ...values,
        };
        dispatch(asyncCreateVariant(data))
            .unwrap()
            .then(() => {
                onCancel();
            });
    };

    const handleUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(true);
        let response: IVariantFields;
        try {
            response = await dispatch(
                asyncFetchVariantFields(e.target.value)
            ).unwrap();
        } catch {
            return;
        } finally {
            setDisabled(false);
            setCompleted(true);
        }

        form.setFieldsValue({
            name: response.name,
            description: response.description,
            price: response.price,
            count: 1,
        });
        if (response.price) setCountRequired(true);
        setImageUrl(response.imageUrl || undefined);
    };

    return (
        <Form
            className="new-variant-form"
            form={form}
            layout="vertical"
            onFinish={onConfirm}
        >
            <Card
                actions={[
                    completed && (
                        <Button
                            block
                            type="text"
                            key="create"
                            htmlType="submit"
                            icon={<PlusOutlined className="green" />}
                        />
                    ),
                    <Button
                        block
                        type="text"
                        key="close"
                        onClick={onCancel}
                        icon={<CloseOutlined className="red" />}
                    />,
                ]}
            >
                <Space direction="vertical" className="block">
                    <Form.Item label="Адрес" name="url">
                        <Search
                            disabled={disabled}
                            loading={disabled}
                            onChange={handleUrlChange}
                            placeholder="URL"
                        />
                    </Form.Item>
                    {completed && (
                        <Space>
                            <Avatar size={250} src={imageUrl || DummyImage} />
                            <Space direction="vertical" className="block">
                                <Form.Item
                                    label="Название"
                                    name="name"
                                    rules={[{ required: true }]}
                                >
                                    <TextArea placeholder="Название" />
                                </Form.Item>
                                <Form.Item label="Описание" name="description">
                                    <TextArea placeholder="Описание" />
                                </Form.Item>
                                <Space.Compact>
                                    <Form.Item label="Цена" name="price">
                                        <Input
                                            onChange={e => {
                                                if (!e.target.value)
                                                    setCountRequired(false);
                                                else setCountRequired(true);
                                            }}
                                            placeholder="Цена"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Количество"
                                        name="count"
                                        rules={[
                                            {
                                                required: countRequired,
                                                message: 'Обязательное поле',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Количество" />
                                    </Form.Item>
                                </Space.Compact>
                            </Space>
                        </Space>
                    )}
                </Space>
            </Card>
        </Form>
    );
}

function VariantsModal({
    open,
    onCancel,
    position,
}: VariantsModalProps): React.ReactElement {
    const dispatch = useAppDispatch();

    const [adding, setAdding] = React.useState(false);

    const handleDeleteVariant = (variant: IVariant) => {
        dispatch(asyncDeleteVariant(variant));
    };

    return (
        <Modal
            footer={null}
            width={800}
            title="Варианты"
            open={open}
            onCancel={onCancel}
        >
            <Space direction="vertical" className="block">
                <div className="variants-flex">
                    {position.variants.map(variant => (
                        <VariantCard
                            onDeleteVariant={handleDeleteVariant}
                            key={variant.id}
                            variant={variant}
                        />
                    ))}
                </div>
                {adding && (
                    <NewVariant
                        position={position}
                        onCancel={() => setAdding(false)}
                    />
                )}
                {!adding && (
                    <Button
                        block
                        icon={<PlusOutlined />}
                        onClick={() => setAdding(true)}
                    >
                        Добавить
                    </Button>
                )}
            </Space>
        </Modal>
    );
}

export default VariantsModal;
