import React from 'react';
import './PositionCard.scss';
import { Card, Col, Input, Row, Space, Button } from 'antd';
import EmojiPicker from 'components/ui/EmojiPicker';
import { IBasePosition } from 'store/modules/positions/types';
import useUpdateFields from 'js/hooks/useUpdateFields';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { NewPositionCardProps } from './types';
import CalculateBlock from './CalculateBlock';

export default function NewPositionCard({
    category,
    onCreate = () => {},
    onCancel = () => {},
}: NewPositionCardProps): React.ReactElement {
    const positionForm = React.useRef<IBasePosition>({
        name: '',
        emoji: '',
        completed: false,
        categoryId: category?.id,
        description: '',
        price: null,
        count: null,
    });

    const { handleUpdateField } = useUpdateFields(positionForm);

    return (
        <Card className="block position-card" size="small">
            <Row className="block" align="middle">
                <Col span={1} className="center">
                    <EmojiPicker
                        name="emoji"
                        defaultValue={positionForm.current.emoji}
                        onEmojiSelect={handleUpdateField}
                    />
                </Col>
                <Col span={4}>
                    <Space direction="vertical">
                        <Input
                            className="hoverable-input block"
                            name="name"
                            defaultValue={positionForm.current.name}
                            onChange={handleUpdateField}
                            placeholder="Название"
                        />
                        <Input
                            className="hoverable-input block"
                            name="description"
                            defaultValue={positionForm.current.description}
                            onChange={handleUpdateField}
                            placeholder="Описание"
                        />
                    </Space>
                </Col>
                <Col span={5} />
                <Col span={7}>
                    <CalculateBlock
                        names={['price', 'count']}
                        position={positionForm.current}
                        onChange={handleUpdateField}
                    />
                </Col>
                <Col span={4} className="center" />
                <Col span={3}>
                    <Space className="block" style={{ justifyContent: 'end' }}>
                        <Button type="text" onClick={onCancel}>
                            <CloseOutlined className="red" />
                        </Button>
                        <Button
                            type="text"
                            onClick={() => onCreate(positionForm.current)}
                        >
                            <CheckOutlined className="green" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Card>
    );
}
