import React from 'react';
import { EventType, Id } from 'js/types';

export default function useUpdateFields<K>(
    form: React.MutableRefObject<K>,
    onFieldChange: (value: Partial<K> & Id) => void = () => {},
    item?: K & Partial<Id>
) {
    const timeout = React.useRef<NodeJS.Timeout>();

    const handleUpdateField = <T extends keyof K>(e: EventType<K[T]>) => {
        const name = e.target.name as T;
        // eslint-disable-next-line no-param-reassign
        form.current[name] = e.target.value;
        const updatedItem = {
            [name]: e.target.value,
            id: item?.id,
        } as K & Id;
        onFieldChange(updatedItem);
    };

    const handleTimeoutUpdateField: typeof handleUpdateField = e => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => handleUpdateField(e), 1000);
    };

    React.useEffect(() => () => clearTimeout(timeout.current), []);

    return { handleUpdateField, handleTimeoutUpdateField };
}
