import { createAsyncThunk } from '@reduxjs/toolkit';
import getApiClient from 'js/apiClient';
import { AxiosResponse } from 'axios';
import { Id } from 'js/types';
import { IBaseCategory, ICategory } from './types';

export const asyncFetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async (_, { rejectWithValue }) =>
        getApiClient('token')
            .get('categories/')
            .then((response: AxiosResponse<ICategory[]>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncPatchCategory = createAsyncThunk<
    ICategory,
    Partial<IBaseCategory> & Id
>('categories/patchCategory', async ({ id, ...data }, { rejectWithValue }) =>
    getApiClient('token')
        .patch(`categories/${id}/`, data)
        .then((response: AxiosResponse<ICategory>) => response.data)
        .catch(err => rejectWithValue(err.response?.data))
);

export const asyncCreateCategory = createAsyncThunk(
    'categories/createCategory',
    async (category: IBaseCategory, { rejectWithValue }) =>
        getApiClient('token')
            .post('categories/', { ...category })
            .then((response: AxiosResponse<ICategory>) => response.data)
            .catch(err => rejectWithValue(err.response?.data))
);

export const asyncDeleteCategory = createAsyncThunk(
    'categories/deleteCategory',
    async (category: ICategory, { rejectWithValue }) =>
        getApiClient('token')
            .delete(`categories/${category.id}/`)
            .then(() => category)
            .catch(err => rejectWithValue(err.response?.data))
);
