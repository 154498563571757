import React from 'react';
import './TotalPrice.scss';
import { Card, Typography } from 'antd';
import { currencyLocaleOptions } from 'js/utils';
import { TotalPriceProps } from './types';

const { Title } = Typography;

function TotalPrice({ positions }: TotalPriceProps): React.ReactElement {
    const [totalPrice, minPrice, maxPrice, spentPrice, diff] =
        React.useMemo(() => {
            let result = 0;
            let minResult = 0;
            let maxResult = 0;
            let totalSpent = 0;
            let totalDiff = 0;
            positions.forEach(position => {
                const notNullMin = position.min || position.actualPrice;
                const notNullMax = position.max || position.actualPrice;
                minResult += notNullMin;
                maxResult += notNullMax;
                result += position.actualPrice || (notNullMin + notNullMax) / 2;
                totalSpent += position.spentPrice;
                totalDiff += position.difference;
            });
            return [result, minResult, maxResult, totalSpent, totalDiff];
        }, [positions]);

    return (
        <Card className="total-price" size="small">
            <div className="total-price_grid">
                <div>Цена</div>
                <Title level={4}>
                    {totalPrice.toLocaleString('ru', currencyLocaleOptions)}
                </Title>
                {minPrice !== totalPrice && (
                    <div>
                        <div>Минимальная цена</div>
                        <Title level={4}>
                            {minPrice.toLocaleString(
                                'ru',
                                currencyLocaleOptions
                            )}
                        </Title>
                    </div>
                )}
                {maxPrice !== totalPrice && (
                    <div>
                        <div>Максимальная цена</div>
                        <Title level={4}>
                            {maxPrice.toLocaleString(
                                'ru',
                                currencyLocaleOptions
                            )}
                        </Title>
                    </div>
                )}
                <div>Потрачено</div>
                <Title level={4}>
                    {spentPrice.toLocaleString('ru', currencyLocaleOptions)}{' '}
                </Title>
                <div>Разница</div>
                <Title level={4} style={{ color: diff > 0 ? 'red' : 'green' }}>
                    {(diff * -1).toLocaleString('ru', currencyLocaleOptions)}{' '}
                </Title>
            </div>
        </Card>
    );
}

export default TotalPrice;
